<template>
  <!-- eslint-disable -->
  <decorator-icon v-bind="attrs">
    <path
        d="M24 8C15.1795 8 8 15.1795 8 24C8 32.8205 15.1795 40 24 40C32.8205 40 40 32.8205 40 24C40 15.1795 32.8205 8 24 8ZM24 37.5385C16.5333 37.5385 10.4615 31.4667 10.4615 24C10.4615 16.5333 16.5333 10.4615 24 10.4615C31.4667 10.4615 37.5385 16.5333 37.5385 24C37.5385 31.4667 31.4667 37.5385 24 37.5385Z"
        fill="inherit"/>
    <path
        d="M24 15.3848C20.6154 15.3848 17.8462 18.154 17.8462 21.5386C17.8462 22.2155 18.4 22.7694 19.077 22.7694C19.7539 22.7694 20.3077 22.2155 20.3077 21.5386C20.3077 19.5078 21.9693 17.8463 24 17.8463C26.0308 17.8463 27.6923 19.5078 27.6923 21.5386C27.6923 23.5694 26.0308 25.2309 24 25.2309C23.3231 25.2309 22.7693 25.7848 22.7693 26.4617V27.6925C22.7693 28.3694 23.3231 28.9232 24 28.9232C24.677 28.9232 25.2308 28.3694 25.2308 27.6925V27.5694C28.0411 26.995 30.1539 24.513 30.1539 21.5386C30.1539 18.154 27.3847 15.3848 24 15.3848Z"
        fill="inherit"/>
    <path
        d="M24 34.4618C25.0196 34.4618 25.8461 33.6353 25.8461 32.6157C25.8461 31.5961 25.0196 30.7695 24 30.7695C22.9804 30.7695 22.1538 31.5961 22.1538 32.6157C22.1538 33.6353 22.9804 34.4618 24 34.4618Z"
        fill="inherit"/>
  </decorator-icon>
</template>

<script>
import DecoratorIcon from '../decorator/dp-icon-decorator'
import MixinIcon from '../mixin/dp-icon-mixin'

/**
 * @group Components-icon-svg
 * Standard components
 */
export default {
  name: 'dp-question-icon',
  mixins: [MixinIcon],
  components: {
    DecoratorIcon
  },
  data() {
    return {
      viewBox: '0 0 48 48'
    }
  }
}
</script>
